var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[(_vm.app.category && _vm.app.category != 'application')?[_c('v-list-item',{attrs:{"link":"","to":{
                    name: 'project_settings',
                    params: { tab: 'applications' },
                    hash: '#categories',
                    query: {
                        category: _vm.app.category,
                    },
                }}},[_c('v-list-item-title',[_vm._v("Edit the \""+_vm._s(_vm.app.category)+"\" category")])],1)]:_vm._e(),_c('v-list-item',{staticClass:"grey--text"},[_vm._v("Move the app to a category")]),_c('v-list-item',{attrs:{"link":"","to":{
                name: 'project_settings',
                params: { tab: 'applications' },
                hash: '#categories',
                query: {
                    app_pattern: _vm.appPattern(),
                },
            }}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("a new category")],1)],1),(_vm.categories)?_vm._l((_vm.categories),function(c){return _c('v-list-item',{attrs:{"link":"","to":{
                    name: 'project_settings',
                    params: { tab: 'applications' },
                    hash: '#categories',
                    query: {
                        category: c,
                        app_pattern: _vm.appPattern(),
                    },
                }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-arrow-right-thin")]),_c('v-list-item-title',[_vm._v(_vm._s(c))])],1)}):_vm._e(),(_vm.app.custom)?_c('v-list-item',{attrs:{"link":"","to":{
                name: 'project_settings',
                params: { tab: 'applications' },
                hash: '#custom-applications',
                query: { custom_app: _vm.$utils.appId(_vm.app.id).name },
            }}},[_c('v-list-item-title',[_vm._v("Edit custom application")])],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }